/* The container must be positioned relative: */
.custom-select {
    background-color: #f9fafb;
    position: relative;
    font-family: Arial;
    appearance: none;
    height: 100%;
    min-height: 3.056vw;
    width: 100%;
    font-size: 1.11vw;
    border: 0.0694vw solid #D0D5DD;
    border-radius: 0.5vw;
    box-sizing: border-box;
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    cursor: pointer;
    display: inline-block;
}

.custom-select:active,
.custom-select:focus,
.custom-select:hover {

    box-shadow: 0 0 0.4vw 0.0833vw rgba(202, 143, 227, 0.7);
    outline: none; /* Rimuove l'outline predefinito del browser */
    background-color: white;
}


.custom-select select {

    display: none; /*hide original SELECT element: */

}

.custom-select.sidebar {
    min-height: 1.6vw;
    border: none;
    border-radius: 0;
}

.custom-select.sidebar:active,
.custom-select.sidebar:focus,
.custom-select.sidebar:hover {

    box-shadow: none;
    outline: none; /* Rimuove l'outline predefinito del browser */
    background-color: white;
}

.custom-select.sidebar > .select-selected {
    font-weight: bold;
}

.select-selected {
    
    appearance: none;
    height: 100%;
    width: 100%;
    padding: 0.74444vw 0.972222vw 0.54444vw 0.972222vw;
    font-size: 1.11vw;
    border-radius: 0.5vw;
    box-sizing: border-box;
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 1;

}


/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: ""; /* Rimuovi il contenuto precedente */
    /*background-image: url("../icon/arrow.svg");*/ /* Usa background-image al posto di content */
    background-size: contain; /* Assicura che l'immagine si adatti all'interno del contenitore */
    background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
    width: 1.2vw; /* Imposta la larghezza desiderata */
    height: 1.2vw; /* Imposta l'altezza desiderata */
    margin-left: auto;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    padding: 0.74444vw 0.972222vw 0.54444vw 0.972222vw;
    border: none;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    z-index: 9999;
    max-height: 11vw;
    overflow-y: auto;
    border: 0.0100vw solid #cdcdcdd9;
    border-radius: 0.69444vw;
    background-color: #ffffff;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

/* Stile delle opzioni quando passo col mouse sopra: */
.select-items div:hover,
.same-as-selected {
    background-color: rgb(219, 219, 219);
    border: none;
}

/* Aggiunta dell'icona solo quando l'opzione è selezionata: */
.select-items div.same-as-selected {
    background-color: rgba(226, 226, 226, 0.998); /* Aggiunto un colore di sfondo */
    background-image: url('../icon/check.svg'); /* Aggiunta dell'immagine di sfondo */
    background-repeat: no-repeat; /* Impedisci la ripetizione dell'immagine di sfondo */
    background-position: calc(100% - 1.69vw) center;


}