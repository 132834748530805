


span {
    font-size: 0.9vw;
}

.bodyMachineMetriche{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 1vw
}