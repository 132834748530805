@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* Ora puoi utilizzare il font Inter nelle tue regole CSS */
body {
    font-family: 'Inter', sans-serif;
}

.sidebarSettings {
    /*margin-left: 6.035vw;*/
    background-color: white;
    width: 13vw;
    min-width: 12vw;
    height: auto;
}

.sidebarSettingsContent {
    background-color: #f9fafb;
    display: flex;
    margin-top: 3vw;
    margin-left: 2vw;
    justify-content: center;
    align-items: center;
}

.mainSettingsUsers {
    display: flex;
    /* NEW CSS */
    margin: auto;
    max-width: 87vw;
    height: auto;

}

.mainSettingsUsersBody {
    display: flex;
    flex-direction: column;
    width: 78.472vw;
    height: min-content;
    background-color: white;
    /*padding: 2vw;*/
    padding-top: 1vw;
    padding-bottom: 2vw;

}

.mainSettingsUsersHeader {
    /*width: 100%;*/
    height: 6.112vw;
    /*background-color: white;*/
    margin-bottom: 3vw;
    padding-left: 2vw;
    padding-right: 2vw;
}

.mainSettingsUsersSection {
    /*margin-left: 2vw;*/
    padding-left: 2vw;
    padding-right: 2vw;

}