.bodyMetriche{
    padding-left: 1.52vw;
    padding-right: 2.84vw;
    background-color: transparent;
    /*min-height: 74.11vh;*/
    /*max-height: 74.11vh;*/

    /* NEW CSS */
    /*height: 20.7vw;*/
    padding-top: 1.1vw;
    padding-bottom: 2vw;

}
