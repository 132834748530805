.upload-file {
    display: flex;
}

.upload-file-avatar {
    display: flex;
}

/* Stili per l'area di rilascio del file */
.upload-file label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 29.445vw;
    height: 8.473vw;
    border: 0.2vw solid;
    border-style: solid;
    border-radius: 0.7vw;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    border-color: #6941c6;
}

/* Stili per i contenuti all'interno dell'area di rilascio del file */
.upload-file label > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0.1vw;
    padding-bottom: 0.1vw;
}

/* Stili per l'icona SVG */
.upload-file svg {
    width: 3vw;
    height: 3vw;
    margin-bottom: 4px;
    color: #888;
}

/* Stili per i paragrafi */
.upload-file p {
    margin-bottom: 2px;
    font-size: 1.1vw;
    color: #888;
    margin-top: 0.3vw;
}

/* Stili per il testo in grassetto */
.upload-file span {
    font-weight: bold;
}

/* Stili per il testo più piccolo */
.upload-file label p:last-child {
    font-size: 1vw;
}

/* Stili per l'input del file nascosto */
.upload-file input[type="file"] {
    display: none;
}


.upload-file.dragging {
    border: 0.2vw dashed #28a745;
    background-color: #f8f9fa;
    cursor: move !important; /* o 'copy' */
}
