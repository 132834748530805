.radio-button-group {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    align-items: flex-start;
}

.radio-button {
    display: inline-block;
    padding: 0.694444vw 2vw 0.694444vw 2vw;
    font-size: 0.972222vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.06944vw solid #D0D5DD;
    border-radius: 0.5vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
    box-sizing: border-box;
    margin-right: 2vw;
    margin-bottom: 2vw;
}

.radio-button:hover {
    background-color: #ad9ad6;
}

.radio-button input {
    display: none;
}

.radio-button.selected {
    background: #7F56D9 url('../icon/Dot.svg') no-repeat center left; /* Viola */
    color: #ffffff;
    border-color: #7F56D9; /* Viola */
    background-position: 1vw;

}

.radio-button.selected:hover {
    background-color: #7F56D9; /* Viola scuro */
}


.radio-button.confirm {
    background-color: #ECFDF3; /* Confermato */
    color: #067647;
    border-color: #ABEFC6;
}

.radio-button.disabled {
    background-color: #FEF3F2; /* Disabilitato */
    color: #B42318;
    border-color: #FECDCA;
}

.radio-button.change-password {
    background-color: #F9FAFB; /* Modifica Password */
    border-color: #EAECF0;
    color: #344054;
}

.radio-button.confirm:hover {
    background-color: #acf4ca;
}

.radio-button.disabled:hover {
    background-color: #fd9a91; /* Disabilitato */
}

.radio-button.change-password:hover {
    background-color: #ccced0 /* Modifica Password */
}


.radio-button.selected.confirm {
    border-width: 0.308vw;
    background-image: none;
}

.radio-button.selected.disabled {
    border-width: 0.308vw;
    background-image: none;
}

.radio-button.selected.change-password {
    border-width: 0.308vw;
    background-image: none;
}

