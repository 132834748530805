.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #555;
}

.closing {
    opacity: 0;
}


.modal-header {
    margin-bottom: 1vw;
}

.modal-actions {
    margin-top: 3vw;
    display: flex;
    justify-content: center;
}

.modal-actions-annulla {
    margin-right: 2vw;
}
