.container {
    height: 30rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 10% 90%;
}

.dendrogram {
    width: 100%;
    height: 17vw;
}

.header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
}

.nodeSelected {


    font-size: 1.2rem;
}




.nodeText {
    align-self: center;
    font-size: 2rem;
}



.nodeSelected {
    fill: blue;
    stroke: #ff7f7f;
}