.mainUserProfileHeader {
    margin-bottom: 2vw;
}

.mainUserProfileHeaderTitle {
    margin: 2vw 0px 1.5vw;
    font-size: 1.4vw;
    font-weight: bold;
}

.mainUserProfileFooter {
    margin-top: 2vw;
}


.mainUserProfileContent {
    display: flex;
    justify-content: flex-end;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 1.8vw;
}