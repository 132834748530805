.bodyDatiTarga{
    width: 50vw;
}

.bodyTableMachineDocument {
    background-color: white;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    
    margin-right: 1.5vw;
    height: 13vw;
}




.elementDatiTargaList {
    /*margin: 0*/
    padding: 0;
    margin-left: 2.64vw;
    /*margin-top: 2.12vh;*/

    /* NEW CSS */
    margin-top: 1vw;
}

.elementDatiTargaElementList {
    margin: auto;
    display: flex;
    margin: 0
}

.elementDatiTargaElementListName {
    flex: 1;
    /*margin: 1vh 0;*/
    /* NEW CSS */
    margin: 0.3vw 0;
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.8vw;
}

.elementDatiTargaElementListValue {
    flex: 1;
    /*margin: 1vh 0;*/
    /* NEW CSS */
    margin: 0.3vw 0;
    font-size: 0.8vw;
}

.elementTitoloTableMachineDocument {
  
    font-size: 1.5vw;
    padding: 1vw 0 0 1vw;
    margin: 0;
}

.boxHeadTableMachineDocument {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* NEW CSS */
   
    margin-left: 2.64vw;
    text-transform: uppercase;
    color: #464F60;
    font-size: 1.2vw;
    /*margin-top: .78vh;*/

}

.boxRowTableMachineDocument {
    display: flex;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;

}

.elementStandardTableMachineDocument {
    /*OLD CSS*/
    flex: 1;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    /*margin-top: 1.34vh;*/
    /*margin-bottom: 1.34vh;*/
    margin-top: 0.8vw;
    margin-bottom: 0.8vw;
}

.elementLongTableMachineDocument {
    /*OLD CSS*/
    flex: 4;
    /*margin-left: 10px;*/

    /*NEW CSS*/
    margin: 0;
}

.backgroundWhiteTableMachineDocument {
    background-color: white;
}

.backgroundBlueTableMachineDocument {
    background-color: #F4F8FB;
}

.elementTestoStandardTableMachineDocument {
    font-weight: normal;

}