.mainFormUserSectionBodyHeader {
    margin-bottom: 2vw;
}

.errorInput{
    border-color: red;
}

.input.email:invalid{
    border-color: red;
}