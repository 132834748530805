.mainUserSettingsFooter {
    margin-top: 2vw;
}

.mainUserSettingsHeaderTitle {
    margin: 2vw 0px 0.6vw;
    font-size: 1.4vw;
    font-weight: bold;
}

.mainUserSettingsHeaderSubTitle {
    margin: 0px 0px 1.5vw;
    font-size: 1.1vw;
    color: rgba(71, 84, 103, 1);
}


.mainUserSettingsFooterContent {
    display: flex;
    justify-content: flex-end;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 1.8vw;
}