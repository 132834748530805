.table {
    background-color: white;
    border: 0.1vw solid rgba(234, 236, 240, 1);
    border-radius: 1vw;
}

.table-body {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    border: 0
}


.filters-bar {
    margin-bottom: 1.5vw;
    display: flex;
    justify-content: space-between;
}

.tbl-header th {
    padding: 1vw;
    padding-left: 1.5vw;
    text-align: left;
    font-weight: 500;
    font-size: 1vw;
    color: rgba(71, 84, 103, 1);
    /* text-transform: uppercase; */
    /*border-radius: 1vw;*/
    font-weight: bold;

    /* NEW PER IL PUNTAMENTO */
    cursor: pointer; /* Aggiungi questa riga per cambiare il cursore in un puntatore */
    /*background-color: rgba(249, 250, 251, 1);*/
}

.tbl-header th:hover {
    background-color: rgba(249, 250, 251, 1); /* Imposta il colore di sfondo desiderato qui */
}


.table-row td {
    height: 1vw;
    padding: 0.85vw;
    padding-left: 1.5vw;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 1vw;
    color: #000000;
    /*border-bottom: solid 1vw rgba(255, 255, 255, 0.1);*/
}

/* Rimuovi altezza fissa per le righe della tabella */
.table-row {
    height: 5vw;
    border-top: 0.1vw solid rgba(234, 236, 240, 1);
    border-bottom: 0.1vw solid rgba(234, 236, 240, 1);
    /* Rimuovi il bordo a destra e a sinistra */
    border-left: none;
    border-right: none;
}

.table-cell {
    display: flex;
    align-items: center;
}


.table-cell-multiple-value {
    display: flex;
    flex-direction: column;
}

.table-row-badge-success {
    font-size: 0.7vw;
    background: #ECFDF3;
    border: 1px solid #ABEFC6;
    border-radius: 9999px;
    padding-left: 0.6vw;
    padding-right: 0.6vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    color: rgba(6, 118, 71, 1);
    font-weight: 600;
}

.table-row-badge-error {
    font-size: 0.7vw;
    background: rgba(254, 243, 242, 1);
    border: 1px solid rgba(254, 205, 202, 1);
    border-radius: 9999px;
    padding-left: 0.6vw;
    padding-right: 0.6vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    color: rgba(180, 35, 24, 1);
    font-weight: 600;
}

.table-row-badge-default {
    font-size: 0.7vw;
    background: rgba(249, 250, 251, 1);
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 9999px;
    padding-left: 0.6vw;
    padding-right: 0.6vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    color: rgba(52, 64, 84, 1);
    font-weight: 600;
}


.table-header {
    display: flex;
    justify-content: space-between;
    height: 4.5vw;
    align-items: center;
    font-size: 1vw;
}

.table-button {

    background-color: #FFFFFF;
    color: #344054;
    display: inline-block;
    padding: 0.55vw 0.83vw 0.55vw 0.83vw;
    font-size: 0.97vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.069vw solid #D0D5DD;
    border-radius: 0.347vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
}

.table-button:hover {
    background-color: #e0e0e0;
}

/* Effetto di evidenziazione durante il clic */
.table-button:active {
    background-color: #d1d1d1;
}

/* Altri stili rimossi per brevità */


/* Aggiungi queste regole per la freccia nell'header della colonna */
.sorted {
    background-color: rgba(249, 250, 251, 1);
    position: relative;
    display: flex;
}

.cursorPointer{
    cursor: pointer;
}