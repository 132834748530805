.button {
    display: inline-block;
    padding: 0.694444vw 0.972222vw 0.694444vw 0.972222vw;
    font-size: 0.972222vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.06944vw solid #D0D5DD;
    border-radius: 0.5vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
    box-sizing: border-box
}

.button.save {
    background-color: #7F56D9;
    color: white;
}

/* Stile specifico per il bottone "cancel" */
.button.cancel {
    background-color: #FFFFFF;
    color: #344054;
}

.button.disabilita {
    background-color: red;
    color: white;
}


.addUser {
    /* Aggiungi stili specifici per l'icona "Salva" */
    background-color: #7F56D9;
    color: white;
    display: inline-block;
    padding: 0.694444vw 1.2vw 0.694444vw 2.3vw;
    font-size: 0.972222vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.069444vw solid #D0D5DD;
    border-radius: 0.5vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
    background-image: url("../icon/addUser.svg");
    background-size: 1.3vw; /* Assicura che l'immagine si adatti all'interno del contenitore */
    background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
    background-position-x: 0.6vw;
    background-position-y: center;
}


.filter {

    background-color: #FFFFFF;
    color: #344054;
    display: inline-block;
    padding: 0.694444vw 1.3vw 0.694444vw 2vw;
    font-size: 0.972222vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.069444vw solid #D0D5DD;
    border-radius: 0.5vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
    background-image: url('../icon/filter.svg'); /* Usa background-image al posto di content */
    background-size: 1vw; /* Assicura che l'immagine si adatti all'interno del contenitore */
    background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
    background-position-x: 0.6vw;
    background-position-y: center;
}


.filter.x {
    padding: 0.694444vw 2vw 0.694444vw 1vw;
    background-image: url('../icon/x.svg'); /* Usa background-image al posto di content */
    background-size: 0.8vw; /* Assicura che l'immagine si adatti all'interno del contenitore */
    background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
    background-position-x: 5vw;
    background-position-y: center;
    margin-right: 1vw;
}


.filter.x::before {
    cursor: pointer; /* Abilita il cursore solo sull'icona */
}

/* Rimuovi il cursore dalla parte di testo del pulsante */
.filter.x {
    cursor: auto; /* Rimuovi il cursore dalla parte di testo del pulsante */
}


/* Stili specifici basati sull'attributo data-type */

/* Effetto di evidenziazione al passaggio del mouse */
.button.save:hover, .addUser:hover {
    background-color: #6b48af;
}

/* Effetto di evidenziazione durante il clic */
.button.save:active, .addUser:active {
    background-color: #543c97;
}

.button.cancel:hover, .filter:hover {
    background-color: #e0e0e0;
}

/* Effetto di evidenziazione durante il clic */
.button.cancel:active, .filter:active {
    background-color: #d1d1d1;
}