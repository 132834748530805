.breadcrumb {
    list-style: none;
    display: flex;
    padding: 0;
    display: flex;
    align-items: center;
    margin-block-start: 1vw;
    margin-block-end: 1vw;
}

.breadcrumb-item {
    font-size: 1.1vw;
    padding: 0.3vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
}

.breadcrumb-item:last-child {
    font-size: 1.1vw;
    margin-right: 0.6vw;
}


.breadcrumb-item > .breadcrumb-badge {
    /* NEW */
    padding: 0.3vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
    background: #F9FAFB;
    border-radius: 0.4vw;
    font-weight: bold;
    color: rgba(52, 64, 84, 1);
}


.breadcrumb-item > .breadcrumb-default {
    /* NEW */
    padding: 0.3vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
    background: transparent;
    border-radius: 0.4vw;
    font-weight: bold;
    color: rgba(52, 64, 84, 1);
}


.breadcrumb-item a {
    font-weight: bold;
    font-size: 1.1vw;
    text-decoration: none;
    color: rgba(71, 84, 103, 1);
}

.breadcrumb-separator {
    /*margin-bottom: 0.3vw;*/
    margin-right: 0.4vw;
    margin-left: 0.4vw;
    color: #999;

}

.breadcrumb-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
