.bodyDesktopInterventoPerMacchinario {
    background-color: white;

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*flex: 3;*/

    /* NEW CSS*/
    /*margin-top: 2vw !important;*/
    /*width: 80.35vw;*/
    /*margin: auto;*/
    width: 100%;
    /*margin-top: 2vh;*/
    margin-top: 2vw;
}

.TitoloDesktopInterventoPerMacchinario {
    /*margin-left: 10px;*/
    /*padding-top: 5vh;*/
    /*font-size: 3vh;*/
    /* NEW CSS */
    font-size: 1.45vw;
    padding: 0.87vw 0 0 1.04vw;
    margin: 0;
}

.headTableDesktopInterventoPerMacchinario {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /*!* NEW CSS *!*/
    gap: 1.16vw;
    margin-left: 1.04vw;
    /*padding: 0 2.90vw 0 0.58vw;*/
    text-transform: uppercase;
    color: #464F60;
}

.RowTableDesktopInterventoPerMacchinario {
    display: flex;
    justify-content: space-between;
    /*max-height: 50px;*/
    align-items: center;
    /*width: 100%;*/

    /*!* NEW CSS *!*/
    /*max-height: fit-content;*/
    min-height: 3.2vw;
    gap: 1.16vw;
    padding-left: 1.04vw;
    padding-top: 0.625vw;
    padding-bottom: 0.625vw;
}

.RowTableDesktopInterventoPerMacchinario h6 {
    margin: 0;
}

.ElemntStandardDesktopInterventoPerMacchinario {
    /*flex: 1;*/
    /*margin-left: 10px;*/

    /* NEW CSS */
    font-size: 1vw;
    flex: 2 !important;
    color: #464F60;
}

.ElemntLongDesktopInterventoPerMacchinario {
    /*flex: 4;*/
    /*margin-left: 10px;*/
    /* NEW CSS*/
    font-size: 1vw;
    flex: 2 !important;
    color: #464F60;
}

.backgroundWhiteDesktopInterventoPerMacchinario {
    background-color: white;
}

.backgroundBlueDesktopInterventoPerMacchinario {
    background-color: #F4F8FB;
}

.testoStandardDesktopInterventoPerMacchinario {
    font-weight: normal;
}

.elementInterventionReportStatusEseguito {
    color: #4F5AED;
    background-color: #F0F1FA;
    border-radius: 10px;
    padding: 2px 10px
}

.elementInterventionReportStatusIncompleto {
    color: crimson;
    background-color: #F0F1FA;
    border-radius: 10px;
    padding: 2px 10px
}

.elementInterventionReportStatusPianificato {
    color: #14804A;
    background-color: #E1FCEF;
    border-radius: 10px;
    padding: 2px 10px
}

.elementInterventionReportStatusDaPianificato {
    color: #FF715B;
    background-color: #FFEDEB;
    border-radius: 10px;
    padding: 2px 10px
}