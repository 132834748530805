/* Aggiungi stili per il componente Input personalizzato */

.input {

    height: 3.056vw;
    width: 100%;
    padding: 0.694444vw 0.972222vw 0.694444vw 0.972222vw;
    font-size: 1.11vw;
    border: 0.0694vw solid #D0D5DD; /* Colore del bordo desiderato */
    border-radius: 0.5vw;
    box-sizing: border-box;
    font-family: 'Inter';
    font-weight: 400;
    color: #101828;
    transition: box-shadow 0.3s ease;
}

/* Aggiungi ombra al focus */
.input:focus {
    box-shadow: 0 0 0.4vw 0.0833vw rgba(202, 143, 227, 0.7);
    outline: none;
}

/* Aggiungi ombra al clic */
.input:active {
    box-shadow: 0.25vw 0.25vw 0.25vw 0.25vw rgba(202, 142, 227, 0.7);
}


.input.search {
    padding-left: 2vw;
    background: url("../icon/search.svg") no-repeat left;
    background-size: 1.11vw;
    background-position: 0.5vw;
    background-color: white;
}

.input.email {
    padding-left: 2vw;
    background: url("../icon/mail.svg") no-repeat left;
    background-color: transparent;
    background-size: 1.11vw;
    background-position: 0.5vw;
}

.input.email:invalid {
}

input:required:invalid, input:focus:invalid {
    border-color: red;
    box-shadow: none;
    outline: none;
}

.input:disabled {
    opacity: 0.5; /* Riduce leggermente l'opacità */
    cursor: not-allowed; /* Cambia il cursore quando l'input è disabilitato */
    background-color: #f2f2f2; /* Sfondo grigio per indicare lo stato disabilitato */
    border-color: #ccc; /* Cambia il colore del bordo per indicare lo stato disabilitato */
}


/* Stili specifici per input di tipo search e email disabilitati */
.input.search:disabled,
.input.email:disabled {
    opacity: 0.5; /* Riduce leggermente l'opacità */
    cursor: not-allowed; /* Cambia il cursore quando l'input è disabilitato */
    background-color: #f2f2f2; /* Sfondo grigio per indicare lo stato disabilitato */
    border-color: #ccc;
}
