.boxElementTitoloSchedeLink:link,
.boxElementTitoloSchedeLink:active,
.boxElementTitoloSchedeLink:visited {

    text-decoration: none;
    white-space: nowrap;
    width: auto;
    color: black;

    margin-right: 1vw;
}

.boxElementTitoloSchede {


    text-decoration: none;


}

.bodyElementTitoloSchede {

    display: flex;
    background-color: white;

    cursor: pointer;
    align-items: center;


    margin-right: 0.5vw;
    padding: 0.5vw 1vw 0.5vw 1vw;
}

.activeSchedaTitoloElementTitoloSchede {
    background-color: lightgray;
}

.boxTitoloSchedeExplore {
    /*OLD CSS*/
    display: flex;
    /*NEW CSS*/
    font-size: .97vw;
    background-color: #f4f8fb;
    overflow: auto;


}