
.tabs {
    display: block;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
}

.tabs [class^="tab"] label,
.tabs [class*=" tab"] label {
    height: 1.8vw;

    color: rgba(102, 112, 133, 1);
    cursor: pointer;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1vw;
    line-height: 1.8vw;
    text-align: center;
    padding: 0.05vw;

}


.tabs [class^="tab"] [type="radio"],
.tabs [class*=" tab"] [type="radio"] {
    cursor: pointer;
    appearance: none;
    display: block;
    width: 100%;
    opacity: 0.1;
    transition: opacity 0.3s; /* Applica una transizione sull'opacità */
    /*padding-top: 0.3vw; !* per posizionare il viola sul devider *!*/

}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked {
    opacity: 1; /* Aumentare l'opacità a 1 quando il radio button è selezionato */
}

.tabs [class^="tab"] [type="radio"]:hover, .tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:hover,
.tabs [class*=" tab"] [type="radio"]:focus {
    margin: 0;
    width: 100%;
    border-bottom: 0.2vw solid #6941c6;
    padding-top: 0.2vw; /* per posizionare il viola sul devider */

    /* NEW CSS*/
    opacity: 1
}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked {
    margin: 0;
    border-bottom: 2px solid #6941c6;
    padding-top: 0.2vw; /* per posizionare il viola sul devider */
}

.tabs [class^="tab"] [type="radio"]:checked + div,
.tabs [class*=" tab"] [type="radio"]:checked + div {
    opacity: 1;
}

.tabs [class^="tab"] [type="radio"] + div,
.tabs [class*=" tab"] [type="radio"] + div {
    display: block;
    opacity: 0;
    width: 90%;
    /*-webkit-transition: all 0.3s ease-in-out;*/
    /*-moz-transition: all 0.3s ease-in-out;*/
    /*-o-transition: all 0.3s ease-in-out;*/
    /*transition: all 0.3s ease-in-out;*/
}

.tabs [class^="tab"] [type="radio"]:checked ~ label,
.tabs [class*=" tab"] [type="radio"]:checked ~ label {
    color: #6941c6; /* Cambia questo al colore viola desiderato */
    padding: 0.05vw;

}

.tabs .tab {
    display: flex;
    flex-direction: column-reverse;
    height: min-content;
    width: max-content;
    margin-right: 1.5vw;

}

.tabs .tab [type="radio"] + div {
    width: 200%;
    margin-left: 200%;
}