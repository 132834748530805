.mainSettingsUserDetailsSection {

}


.mainUserDetailsFooter {
    margin-top: 2vw;
}

.mainUserDetailsFooterContent {
    display: flex;
    justify-content: space-between;
    margin-top: 1.8vw;
}


.mainUserDetailsHeader {
    padding-left: 2vw;
    padding-right: 2vw;

}

.mainUserDetailsFormBody {
    padding-left: 2vw;
    padding-right: 2vw;

}


