.mainFiltersBody {
    display: flex;
    flex-direction: column;
    width: 69.45vw;
    height: 52.643vw;
    background-color: white;
}

.mainFiltersHeader {
    width: 100%;
    height: 3.40vw;
    color: black;
    font-size: 1.25vw;
    font-weight: bolder;
}

.contentHeaderFilters {
    width: 100%;
    height: 1.80vw;
    margin-bottom: 1.39vw;
}

.mainFormFilters {
    width: 100%;
    height: 47.6vw;
}


.dividerFilters {


    width: 100%;
    height: 0.0694vw;

    background: #EAECF0;


    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;

}


.form-labelFilters {
    min-width: 19.5vw;
    display: inline-grid;
    font-size: 0.972vw;
    font-weight: bolder;
}


.form-value-doubleFilters {
    width: 16.4vw;
    height: 2.7vw;
    margin-right: 2vw;
}

.form-valueFilters {
    width: 22.22vw;
    height: 2.7vw;
}

.form-groupFilters {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
}

.mainFiltersBottom {
    width: 100%;
    height: 3.89vw;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 2vw;
}