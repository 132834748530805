.boxIconUser{
    /*OLD CSS*/
    display: flex;
    align-items: center;
    position:relative;
    /*margin: 0 5px 0 20px;*/
    cursor: pointer;

    /*NEW CSS*/
    
}

.logoUtenteElement{
    /*OLD CSS*/
    /*width: 45px;*/
    /*height: 45px;*/
    background-color: white;
    border-radius: 50%;
    /*margin-right: 10px;*/

    /* NEW CSS*/
    width: 3.169vw;
    height: 3.169vw;
    margin-right: 1.1vw;
    padding: 0.5vw;

}

.testoNomeUtente{
    /*OLD CSS*/
    color: #495672;
    /*font-size: 0.7vw;*/
    font-weight: bolder;

    /*NEW CSS*/
    font-size: 1.2vw;
}

.testoRuoloUtente{
    /*OLD CSS*/
    color: #495672;
    /*font-size: 0.7vw;*/

    /*NEW CSS*/
    font-size: 1.2vw;
}

.bodyMenuIconUser{
    /*OLD CSS*/
    position: absolute;
    background-color: white;
    /*min-width: 200px;  */
    /*left: -6vw; */
    /*top: 45px;*/
    /*padding:10px;*/
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);

    /*NEW CSS*/
    padding: 1vw 1.45vw 1vw 1.45vw;
    top: 4.169vw;
    left: -2.5vw;
    max-width: 11.65vw;
    max-height: fit-content;
}

.menuIconUserLogout{
    /*OLD CSS*/
    display: flex;
    cursor: pointer;

    /*NEW CSS*/
   
    align-items: center;
    margin: 1vw 0;
}

.testoMenuUser{
    /*OLD CSS*/
    /*font-size: small;*/
    padding:0px;
    /*margin:0px 5px;*/
    
    /*NEW CSS*/
    font-size: 1vw ;
    margin:0 .3vw;
}