.avatar {
    vertical-align: middle;
    width: 4.445vw;
    height: 4.445vw;
    border-radius: 50%;
    margin-right: 1.388vw;
}

.avatar img {
    width: 100%; /* Assicura che l'immagine riempia completamente il contenitore */
    height: 100%; /* Assicura che l'immagine riempia completamente il contenitore */
    object-fit: cover; /* Adatta l'immagine senza distorcerla */
    border-radius: 50%; /* Mantieni il bordo rotondo */
}

.avatar-default {
    margin-right: 1.388vw;
}

.avatar-table-default {
    vertical-align: middle;
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    margin-right: 0.7vw;
}

.avatar-table-default img {
    width: 100%; /* Assicura che l'immagine riempia completamente il contenitore */
    height: 100%; /* Assicura che l'immagine riempia completamente il contenitore */
    object-fit: cover; /* Adatta l'immagine senza distorcerla */
    border-radius: 50%; /* Mantieni il bordo rotondo */
}


.avatar-table {
    margin-right: 0.7vw;
}