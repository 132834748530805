.divider {
    /* Divider */

    width: 100%;
    height: 0.05vw;
    background: #EAECF0;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;

}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4vw;
    width: 100%;
    height: auto;
}

.form-label {
    font-size: 1.08vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    width: 19.445vw;
    max-width: 19.445vw;
}

.form-label-subvalue {
    font-size: 1vw;
    width: 20vw;
    font-style: normal;
    font-weight: 400;
    color: #475467;

}

.form-label-help-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.1vw;
    width: auto;
}

.form-double {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.9vw;
    width: 35.556vw;
    min-width: 30vw;
    max-width: 35.556vw;
}

.form-single {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 35.556vw;
    min-width: 30vw;
    max-width: 35.556vw;
}

.form-value {
    width: 35.35vw;
    height: 2.7vw;
}

.form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1.6vw 2.6vw;
    width: 100%;
    height: auto;
}
