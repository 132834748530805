@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* Ora puoi utilizzare il font Inter nelle tue regole CSS */
body {
    font-family: 'Inter', sans-serif;
}

.mainUser {
    height: auto;
    width: 78.334vw;
    margin-top: 4vw;
    margin-left: 8vw;
    background-color: white;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.mainUsersHeaderTab {
    margin-left: 2.223vw;
    margin-right: 2.223vw;

}

.mainUserSection {
    margin-left: 2.223vw;
    margin-right: 2.223vw;
}