
.mainNewUserSection {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    /*margin-top: 4vw;*/
    /*margin-left: 8vw;*/
    background-color: white;
    /*padding: 2vw;*/
    /*padding-top: 1vw;*/
}

.mainNewUserSectionHeader {
    /*width: 100%;*/
    padding-left: 2vw;
    padding-right: 2vw;
}

.mainNewUserSectionBody {
    padding-left: 2vw;
    padding-right: 2vw;

}

.mainNewUserFooter {
    margin-top: 2vw;
}


.mainNewUserFooterContent {
    display: flex;
    justify-content: flex-end;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 1.8vw;
}